export default [
  "Bibs",
  "Story book",
  "Socks/booties",
  "Wipes",
  "Disposable diapers",
  "Cloth diapers",
  "Burp cloths",
  "Nursery decor",
  "Bath soap",
  "Gift set",
  "Blanket/swaddle",
  "Wash cloths",
  "Baby lotion",
  "Diaper cream",
  "Bottles",
  "Rattle",
  "Teether",
  "Pacifier",
  "Hooded towel ",
  "Bouncer",
  "Swing",
  "Walker",
  "Night light",
  "Storage/organizer",
  "Laundry detergent",
  "Spoons",
  "Teddy bear",
  "Baby doll",
  "Piggy bank",
  "Diaper bag",
  "Wrap/carrier",
  "High chair",
  "Stroller",
  "Car seat",
  "Baby brush",
  "Outlet plug covers",
  "Thermometer",
  "Musical toy",
  "Homemade gift",
  "Breast pump",
  "Mobile",
  "Photo album/memory book",
  "Picture frame",
  "Headband/bows",
  "Baby bowl/plate",
  "Gift card",
  "Sound machine",
  "Baby monitor",
  "Sippy cup",
  "Changing pad",
  "Play mat",
  "Bottle warmer",
  "Mom gift",
  "Dad gift",
  "Baby shoes",
  "Pajamas",
  "Onesies",
  "Baby dress",
  "Bath toy",
  "Mittens",
];
